import { FundDetails } from "../../models/fundDetails";

const getDefaultValue = (value: any, defaultValue: any) =>
  value ?? defaultValue;

const checkIfPercentage = (type?: string) => {
  return !!(type && type === "percentage");
};

export default function getEditFundDefaultValues(
  initialFundDetails: FundDetails | null | undefined,
) {
  const nonPerformance = initialFundDetails?.assumptions.fees.nonPerformance;
  const performance = initialFundDetails?.assumptions.fees.performance;
  const previousPeriodInputs =
    initialFundDetails?.assumptions.previousPeriodInputs;
  const fundInformation = initialFundDetails?.assumptions.fundInformation;

  return {
    fees: {
      nonPerformance: {
        calculationType: getDefaultValue(nonPerformance?.calculationType, ""),
        reFee:
          getDefaultValue(nonPerformance?.reFee, 0) *
          (checkIfPercentage(nonPerformance?.reFeeType) ? 100 : 1),
        reFeeType: getDefaultValue(nonPerformance?.reFeeType, ""),
        managementFee:
          getDefaultValue(nonPerformance?.managementFee, 0) *
          (checkIfPercentage(nonPerformance?.managementFeeType) ? 100 : 1),
        managementFeeType: getDefaultValue(
          nonPerformance?.managementFeeType,
          "",
        ),
        adminFee:
          getDefaultValue(nonPerformance?.adminFee, 0) *
          (checkIfPercentage(nonPerformance?.adminFeeType) ? 100 : 1),
        adminFeeType: getDefaultValue(nonPerformance?.adminFeeType, ""),
        registryFee:
          getDefaultValue(nonPerformance?.registryFee, 0) *
          (checkIfPercentage(nonPerformance?.registryFeeType) ? 100 : 1),
        registryFeeType: getDefaultValue(nonPerformance?.registryFeeType, ""),
        ieeFee:
          getDefaultValue(nonPerformance?.ieeFee, 0) *
          (checkIfPercentage(nonPerformance?.ieeFeeType) ? 100 : 1),
        ieeFeeType: getDefaultValue(nonPerformance?.ieeFeeType, ""),
        imfFee:
          getDefaultValue(nonPerformance?.imfFee, 0) *
          (checkIfPercentage(nonPerformance?.imfFeeType) ? 100 : 1),
        imfFeeType: getDefaultValue(nonPerformance?.imfFeeType, ""),
      },
      performance: {
        rateType: getDefaultValue(performance?.rateType, ""),
        marginAboveRate: getDefaultValue(performance?.marginAboveRate, 0) * 100,
        returnAboveHurdle:
          getDefaultValue(performance?.returnAboveHurdle, 0) * 100,
        performanceFeeFloorHurdle:
          getDefaultValue(performance?.performanceFeeFloorHurdle, 0) * 100,
        allowanceForRitc: getDefaultValue(performance?.allowanceForRitc, 0),
        crystalisedPerformanceFee: getDefaultValue(
          performance?.crystalisedPerformanceFee,
          0,
        ),
      },
    },
    previousPeriodInputs: {
      closingDate: getDefaultValue(previousPeriodInputs?.closingDate, ""),
      closingNav: getDefaultValue(previousPeriodInputs?.closingNav, 0),
      applicationUnits: getDefaultValue(
        previousPeriodInputs?.applicationUnits,
        0,
      ),
      redemptionUnits: getDefaultValue(
        previousPeriodInputs?.redemptionUnits,
        0,
      ),
      navUnitPrice: getDefaultValue(previousPeriodInputs?.navUnitPrice, 0),
      capOnPerformanceFee: getDefaultValue(
        previousPeriodInputs?.capOnPerformanceFee,
        0,
      ),
      periodUnderPerformance: getDefaultValue(
        previousPeriodInputs?.periodUnderPerformance,
        0,
      ),
    },
    fundInformation: {
      name: getDefaultValue(fundInformation?.name, ""),
      code: getDefaultValue(fundInformation?.code, ""),
      preparedBy: getDefaultValue(fundInformation?.preparedBy, ""),
      apir: getDefaultValue(fundInformation?.apir, ""),
    },
  };
}
