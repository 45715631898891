import { FundAssumptions } from "../models/fundDetails";

// Post process assumptions
// Percentage values should be stored as decimals
export function postProcessAssumptions(assumptions: FundAssumptions) {
  return {
    ...assumptions,
    fees: {
      nonPerformance: {
        ...assumptions.fees.nonPerformance,
        adminFee:
          assumptions.fees.nonPerformance.adminFeeType === "percentage"
            ? assumptions.fees.nonPerformance.adminFee / 100
            : assumptions.fees.nonPerformance.adminFee,
        registryFee:
          assumptions.fees.nonPerformance.registryFeeType === "percentage"
            ? assumptions.fees.nonPerformance.registryFee / 100
            : assumptions.fees.nonPerformance.registryFee,
        ieeFee:
          assumptions.fees.nonPerformance.ieeFeeType === "percentage"
            ? assumptions.fees.nonPerformance.ieeFee / 100
            : assumptions.fees.nonPerformance.ieeFee,
        imfFee:
          assumptions.fees.nonPerformance.imfFeeType === "percentage"
            ? assumptions.fees.nonPerformance.imfFee / 100
            : assumptions.fees.nonPerformance.imfFee,
        managementFee:
          assumptions.fees.nonPerformance.managementFeeType === "percentage"
            ? assumptions.fees.nonPerformance.managementFee / 100
            : assumptions.fees.nonPerformance.managementFee,
        reFee:
          assumptions.fees.nonPerformance.reFeeType === "percentage"
            ? assumptions.fees.nonPerformance.reFee / 100
            : assumptions.fees.nonPerformance.reFee,
      },
      performance: {
        ...assumptions.fees.performance,
        marginAboveRate: assumptions.fees.performance.marginAboveRate / 100,
        returnAboveHurdle: assumptions.fees.performance.returnAboveHurdle / 100,
        performanceFeeFloorHurdle:
          assumptions.fees.performance.performanceFeeFloorHurdle / 100,
      },
    },
  };
}
