import { createSlice } from "@reduxjs/toolkit";
import { postFinancialStatements } from "./accountingFunctions";

const initialState: { loading: boolean } = {
  loading: false,
};

export const accountingSlice = createSlice({
  name: "accounting",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(postFinancialStatements.pending, (state) => {
        state.loading = true;
      })
      .addCase(postFinancialStatements.fulfilled, (state) => {
        state.loading = false;
      });
  },
});

export default accountingSlice.reducer;
