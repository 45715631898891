import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import React from "react";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { NewRun } from "./RunCreationModal";
import {
  checkDateBefore,
  checkDateSame,
  checkDateSameOrBefore,
  convertToDateString,
  getLastBusinessDayOfMonth,
} from "../../utils/DateUtils";
import ProgressButton from "../../components/ProgressButton/ProgressButton";
import AddIcon from "@mui/icons-material/Add";
import { useAppDispatch, useAppSelector } from "../../store/store";
import { getRuns, postPricingRun } from "../../store/run/runFunction";
import DatePickerControl from "../../components/DatePickerControl/DatePickerControl";

interface SelectMonthlyDistributionsProps {
  activeStep: number;
  handleBack: () => void;
  handleReset: () => void;
  handleClose: () => void;
  newRun: NewRun;
}

export default function SelectMonthlyDistributionsStep({
  activeStep,
  handleBack,
  handleReset,
  handleClose,
  newRun,
}: SelectMonthlyDistributionsProps) {
  const loading = useAppSelector((state) => state.runs.loading);
  const dispatch = useAppDispatch();

  // If start of new model, clear out monthly distributions. Else recall distributions previous model
  const monthlyDistributions = checkDateSame(
    newRun.modelTiming.runDate,
    newRun.modelTiming.startDate,
  )
    ? []
    : newRun.monthlyDistributions;

  const runDate = new Date(newRun.modelTiming.runDate);

  // Get the next distribution if it has been previously set
  const nextDistribution = monthlyDistributions.find((distribution) => {
    return checkDateSameOrBefore(runDate, distribution.date);
  });

  const previousDistributions = nextDistribution
    ? monthlyDistributions.filter((distribution) =>
        checkDateBefore(distribution.date, nextDistribution.date),
      )
    : monthlyDistributions;

  // Get the most recent distribution
  const recentDistribution =
    previousDistributions.length > 0
      ? previousDistributions.reduce((latest, distribution) => {
          return checkDateBefore(latest.date, distribution.date)
            ? distribution
            : latest;
        })
      : null;

  // Check if the most recent distribution was for the current month.
  // The inputs should be disabled if true to prevent more than one distribution per month
  const currentDistributionComplete = recentDistribution
    ? new Date(recentDistribution.date).getMonth() ===
        new Date(runDate).getMonth() &&
      new Date(recentDistribution.date).getFullYear() ===
        new Date(runDate).getFullYear()
    : false;

  // Define Zod schema for form validation
  const schema = z
    .object({
      date: z.date(),
      amountPerUnit: z.coerce.number(),
    })
    .refine(
      (schema) => {
        return checkDateSameOrBefore(runDate, schema.date);
      },
      {
        message: "Date cannot be before run date",
        path: ["date"],
      },
    );

  type FormData = z.infer<typeof schema>;
  const { control, handleSubmit } = useForm<FormData>({
    mode: "onBlur",
    reValidateMode: "onChange",
    resolver: zodResolver(schema),
    defaultValues: {
      date: nextDistribution
        ? new Date(nextDistribution.date)
        : getLastBusinessDayOfMonth(runDate),
      amountPerUnit: nextDistribution ? nextDistribution.amountPerUnit : 0,
    },
  });

  return (
    <>
      <form
        onSubmit={handleSubmit(async (data) => {
          const updatedDistributions = previousDistributions;
          if (!currentDistributionComplete) {
            updatedDistributions.push({
              date: convertToDateString(data.date),
              amountPerUnit: data.amountPerUnit,
            });
          }
          const payload = {
            ...newRun,
            monthlyDistributions: updatedDistributions,
          };
          payload.cashRate = payload.cashRate / 100; // Convert from percentage to decimal
          await dispatch(postPricingRun(payload));
          await dispatch(getRuns(null));
          handleReset();
          handleClose();
        })}
      >
        <Grid
          item
          container
          rowSpacing={6}
          columnSpacing={2}
          alignItems="center"
          paddingTop={6}
        >
          <Grid item xs={12} container columnSpacing={2}>
            <Grid item xs={6}>
              <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                Previous Monthly Distributions:
              </Typography>
            </Grid>
            <Grid item container direction="column" rowSpacing={1} xs={6}>
              <TableContainer component={Paper}>
                <Table size="small" aria-label="a dense table">
                  <TableBody>
                    {previousDistributions.map((distribution) => (
                      <TableRow key={distribution.date.toString()}>
                        <TableCell
                          align="left"
                          sx={{ paddingTop: "2px", paddingBottom: "2px" }}
                        >
                          {distribution.date.toString()}
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{ paddingTop: "2px", paddingBottom: "2px" }}
                        >
                          {distribution.amountPerUnit}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
          <Grid item xs={12} container columnSpacing={2} alignItems="center">
            <Grid item xs={4}>
              <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                Next Distribution
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <DatePickerControl
                name="date"
                control={control}
                disabled={currentDistributionComplete}
              />
            </Grid>
            <Grid item xs={4}>
              <Controller
                name="amountPerUnit"
                control={control}
                render={({
                  field: { value, onChange, onBlur, ref },
                  fieldState: { error },
                }) => (
                  <FormControl fullWidth>
                    <TextField
                      name="july"
                      size="small"
                      required
                      type="number"
                      inputRef={ref}
                      value={value}
                      InputLabelProps={{ shrink: false }}
                      onChange={onChange}
                      onBlur={onBlur}
                      error={Boolean(error)}
                      disabled={currentDistributionComplete}
                    />
                    <FormHelperText
                      sx={{
                        color: "error.main",
                      }}
                    >
                      {error?.message ?? ""}
                    </FormHelperText>
                  </FormControl>
                )}
              />
            </Grid>
          </Grid>
          {/* ------------------------------------------------------------- */}
          <Grid
            container
            spacing={4}
            alignItems="flex-end"
            justifyContent="flex-end"
            style={{ height: "100%" }}
            paddingTop={6}
          >
            <Grid item xs={12} container justifyContent="flex-end">
              <Button
                color="inherit"
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 1 }}
              >
                Back
              </Button>
              <Box sx={{ flex: "1 1 auto" }} />
              <Button
                onClick={() => {
                  handleReset();
                  handleClose();
                }}
                sx={{ marginRight: 1 }}
              >
                Cancel
              </Button>
              <ProgressButton
                inProgress={loading}
                text="Start Run"
                Icon={AddIcon}
                color="#ffffff"
                backgroundColor="#2040cd"
                disabled={loading}
              />
            </Grid>
          </Grid>
        </Grid>
      </form>
    </>
  );
}
