import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import React from "react";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { NewRun } from "./RunCreationModal";
import { FundDetails } from "../../models/fundDetails";
import { getLastCompletedRun } from "../../store/run/runFunction";
import { useAppDispatch, useAppSelector } from "../../store/store";
import ProgressButton from "../../components/ProgressButton/ProgressButton";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import TextFieldControl from "../../components/TextFieldControl/TextFieldControl";
import NewRunInputGridItemContainer from "./Components/NewRunInputGridItemContainer/NewRunInputGridItemContainer";
import NewRunFormLabel from "./Components/NewRunFormLabel/NewRunFormLabel";
import NewRunInputGridItem from "./Components/NewRunInputGridItem/NewRunInputGridItem";
import NewRunNavGridContainer from "./Components/NewRunNavGridContainer/NewRunNavGridContainer";

interface SelectFundStepProps {
  funds: FundDetails[];
  activeStep: number;
  handleBack: () => void;
  handleNext: (data: { [key: string]: any }) => void;
  handleReset: () => void;
  handleClose: () => void;
  newRun: NewRun;
  setPriorDayUnits: (x: number) => void;
}

// Define Zod schema for form validation
const schema = z.object({
  fundId: z
    .number()
    .int()
    .positive("Fund is required")
    .refine((val) => val !== 0, "Select a fund"),
  cashRate: z.coerce.number().refine((val) => val !== 0, "Cannot be zero"),
});

export default function SelectFundStep({
  funds,
  activeStep,
  handleBack,
  handleNext,
  handleReset,
  handleClose,
  newRun,
  setPriorDayUnits,
}: SelectFundStepProps) {
  const loading = useAppSelector((state) => state.runs.loading);
  const dispatch = useAppDispatch();
  type FormData = z.infer<typeof schema>;
  const { control, handleSubmit } = useForm<FormData>({
    mode: "onBlur",
    reValidateMode: "onChange",
    resolver: zodResolver(schema),
    defaultValues: {
      fundId: newRun.fundId,
      cashRate: newRun.cashRate,
    },
  });

  return (
    <>
      <form
        onSubmit={handleSubmit(async (data) => {
          const lastRun = await dispatch(getLastCompletedRun(data.fundId));
          if (lastRun.payload.metadata) {
            setPriorDayUnits(
              lastRun.payload.metadata.unitPriceDataModel.unitsAllocations
                .unitsOnIssue,
            );
            handleNext({
              ...data,
              monthlyDistributions:
                lastRun.payload.metadata.unitPriceDataModel
                  .monthlyDistributions,
            });
          }
        })}
      >
        <NewRunInputGridItemContainer>
          {/* ------------------------------------------------------------- */}
          {/* FUND */}
          <NewRunFormLabel label="Fund" />
          <NewRunInputGridItem>
            <Controller
              name="fundId"
              control={control}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => (
                <FormControl fullWidth size="small" sx={{ minWidth: "120px" }}>
                  <Select
                    labelId="funds-select-label"
                    id="funds-select"
                    label="Fund"
                    displayEmpty
                    value={value} // Set the value prop to selectedFund
                    onChange={onChange} // Set the onChange prop to handleSelectedFundChange
                    inputProps={{ "aria-label": "Without label" }}
                    sx={{
                      backgroundColor: "#FFFFFF",
                      ".MuiOutlinedInput-notchedOutline": {
                        border: "none",
                      },
                      border: "1px solid",
                      borderColor: error ? "red" : "#CBCBCB",
                      height: "34px",
                    }}
                  >
                    <MenuItem value={0}>Select Fund</MenuItem>
                    {funds.map((fund) => (
                      <MenuItem key={fund.fundId} value={fund.fundId}>
                        {fund.fund.code}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText
                    sx={{
                      color: "error.main",
                    }}
                  >
                    {error?.message ?? ""}
                  </FormHelperText>
                </FormControl>
              )}
            />
          </NewRunInputGridItem>
          {/* ------------------------------------------------------------- */}
          {/* CASH RATE */}
          <NewRunFormLabel label="Cash Rate" />
          <NewRunInputGridItem>
            <TextFieldControl
              name="cashRate"
              control={control}
              placeholder="Cash rate"
              endAdornment="%"
            />
          </NewRunInputGridItem>
          {/* ------------------------------------------------------------- */}
          <NewRunNavGridContainer>
            <Button
              color="inherit"
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ mr: 1 }}
            >
              Back
            </Button>
            <Box sx={{ flex: "1 1 auto" }} />
            <Button
              onClick={() => {
                handleReset();
                handleClose();
              }}
              sx={{ marginRight: 1 }}
            >
              Cancel
            </Button>
            <ProgressButton
              inProgress={loading}
              text="Next"
              Icon={NavigateNextIcon}
              color="#ffffff"
              backgroundColor="#2040cd"
            />
          </NewRunNavGridContainer>
        </NewRunInputGridItemContainer>
      </form>
    </>
  );
}
