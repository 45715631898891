import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import React from "react";
import { getRuns, putPricingRun } from "../../store/run/runFunction";
import ProgressButton from "../../components/ProgressButton/ProgressButton";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { useAppDispatch, useAppSelector } from "../../store/store";

type ApproveRunModalProps = {
  open: boolean;
  handleClose: () => void;
  runId: number;
};

export default function ApproveRunModal({
  open,
  handleClose,
  runId,
}: ApproveRunModalProps) {
  const loading = useAppSelector((state) => state.runs.loading);
  const dispatch = useAppDispatch();

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{"Approve Pricing Run"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          By clicking 'Approve', the pricing run will be marked as completed and
          will be distributed. Do you want to proceed?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Back</Button>
        <ProgressButton
          inProgress={loading}
          text="Approve"
          Icon={NavigateNextIcon}
          color="#ffffff"
          backgroundColor="#2040cd"
          onClick={async () => {
            await dispatch(putPricingRun({ runId, status: "Completed" }));
            await dispatch(getRuns(null));
            handleClose();
          }}
        />
      </DialogActions>
    </Dialog>
  );
}
