import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  apiErrorHandling,
  apiGetRequests,
  apiPostRequests,
  apiPutRequests,
} from "../apiUtils";
import { NewRun } from "../../forms/RunCreation/RunCreationModal";
import { addNotification } from "../notifications/notificationSlice";

// RETRIEVE RUNS
export const getRuns = createAsyncThunk(
  "run/getRuns",
  async (date: string | null, thunkAPI) => {
    try {
      let suffix = "/run";
      if (date) {
        suffix = `/run?date=${date}`;
      }
      const res = await apiGetRequests(suffix, thunkAPI);
      return res.data;
    } catch (error) {
      let message = "Problem occurred retrieving runs";
      return apiErrorHandling(error, message, thunkAPI);
    }
  },
);

// Get last completed pricing run for fund
export const getLastCompletedRun = createAsyncThunk(
  "run/getRun",
  async (fundId: number, thunkAPI) => {
    try {
      const res = await apiGetRequests(`/run/${fundId}`, thunkAPI);
      return res.data;
    } catch (error) {
      let message = "Problem occurred retrieving last run";
      return apiErrorHandling(error, message, thunkAPI);
    }
  },
);

// Get audit report for display
export const getAuditReport = createAsyncThunk(
  "auditReport/getAuditReport",
  async (runId: number, thunkAPI) => {
    try {
      const res = await apiGetRequests(`/auditReport/${runId}`, thunkAPI);
      return res.data;
    } catch (error) {
      let message = "Problem occurred retrieving audit report";
      return apiErrorHandling(error, message, thunkAPI);
    }
  },
);

export const postPricingRun = createAsyncThunk(
  "run/postRun",
  async (newRun: NewRun, thunkAPI) => {
    try {
      const run = await apiPostRequests("/unitprice", newRun, thunkAPI);
      thunkAPI.dispatch(
        addNotification("New pricing run started successfully", "success"),
      );
      return run;
    } catch (error) {
      let message = "Problem occurred creating new pricing run";
      return apiErrorHandling(error, message, thunkAPI);
    }
  },
);

// Update pricing run
export const putPricingRun = createAsyncThunk(
  "run/putRun",
  async (payload: { status: string; runId: number }, thunkAPI) => {
    try {
      const run = await apiPutRequests(
        `/run/${payload.runId}`,
        { status: payload.status },
        thunkAPI,
      );
      thunkAPI.dispatch(
        addNotification("Pricing run updated successfully", "success"),
      );
      return run;
    } catch (error) {
      let message = "Problem occurred updating pricing run";
      return apiErrorHandling(error, message, thunkAPI);
    }
  },
);
