import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiErrorHandling, apiPostRequests } from "../apiUtils";
import { addNotification } from "../notifications/notificationSlice";

export const postFinancialStatements = createAsyncThunk(
  "accounting/postFinancialStatements",
  async (payload: FormData, thunkAPI) => {
    try {
      await apiPostRequests("/accounting", payload, thunkAPI, {
        "Content-Type": "multipart/form-data",
      });
      thunkAPI.dispatch(
        addNotification(
          "Financial statements uploaded successfully",
          "success",
        ),
      );
    } catch (error) {
      let message = "Problem occurred uploading financial statements";
      return apiErrorHandling(error, message, thunkAPI);
    }
  },
);
